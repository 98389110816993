/**
 * A deferred can be used to create a promise without encapsulation. A normal promise only allows the calback function inside of it to resolve it and only the called to await it.
 * A deferred however has no such separation. Resolving and awaiting the promise are simply functions on it, available for everyone. Deferred are not intended to replace promises, both have separate use cases.
 */
export default class Deferred<T> {
  private _resolver!: (value?: T) => void;
  private _rejector!: (error?: Error) => void;

  /**
   * The promise that can be awaited.
   */
  public readonly promise: Promise<T>;

  /**
   * Creates a new deferred.
   */
  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this._resolver = resolve as (value?: T) => void;
      this._rejector = reject;
    });
    this.promise.then(
      () => this._finalize,
      () => this._finalize
    );
  }

  /**
   * Resolves the deferred as a success with the given value.
   * @param value The value
   */
  public resolve(value?: T) {
    this._resolver(value);
  }

  /**
   * Rejects the deferred with a given error.
   * @param error The error.
   */
  public reject(error?: Error) {
    this._rejector(error);
  }

  protected _finalize() {
    this._resolver = () => { return };
    this._rejector = () => { return };
  }
}
