import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createSlots as _createSlots, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "bp-video-play-view" }
const _hoisted_2 = { class: "bp-video-play-view__chat-wrapper" }
const _hoisted_3 = {
  class: "bp-video-play-view__chat",
  ref: "chatEl"
}
const _hoisted_4 = ["data-message-id"]
const _hoisted_5 = {
  key: 0,
  class: "bp-video-play-view__chat-message-system"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 0,
  class: "bp-video-play-view__chat-message-user"
}
const _hoisted_8 = {
  key: 0,
  class: "flex gap-md"
}
const _hoisted_9 = { class: "bp-video-play-view__chat-message-content" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 0,
  class: "bp-video-play-view__chat-message-quote-user"
}
const _hoisted_12 = { class: "bp-video-play-view__chat-message-action bp-video-play-view__chat-message-action--content" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "bp-video-play-view__chat-message-meta" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = {
  key: 0,
  class: "bp-video-play-view__message-wrapper"
}
const _hoisted_17 = {
  class: "flex",
  style: {"justify-content":"space-between","width":"100%"}
}
const _hoisted_18 = {
  key: 0,
  class: "bp-video-play-view__chat-message-quote-user"
}
const _hoisted_19 = {
  class: "flex gap-md",
  style: {"align-items":"center"}
}
const _hoisted_20 = { class: "flex-1" }
const _hoisted_21 = { class: "bp-video-play-view__setting-wrapper" }
const _hoisted_22 = { class: "bp-video-play-view__user-list" }
const _hoisted_23 = { key: 1 }
const _hoisted_24 = {
  key: 0,
  class: "flex gap-md p-md pr-sm",
  style: {"justify-content":"flex-end"}
}
const _hoisted_25 = { class: "flex-column" }
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = {
  class: "flex gap-md",
  style: {"flex-wrap":"wrap","margin-right":"auto","color":"var(--theme-text-secondary)","font-size":"smaller","text-transform":"lowercase"}
}
const _hoisted_28 = ["title", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_loading = _resolveComponent("bp-loading")!
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_popover = _resolveComponent("bp-popover")!
  const _component_bp_table = _resolveComponent("bp-table")!
  const _component_bp_toggle_button = _resolveComponent("bp-toggle-button")!
  const _component_bp_youtube_iframe = _resolveComponent("bp-youtube-iframe")!
  const _component_bp_card = _resolveComponent("bp-card")!
  const _component_bp_window = _resolveComponent("bp-window")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bp_view_header, {
      class: "bp-video-play-view__header",
      image: "/static/background/app_background_video.jpg",
      icon: !!_ctx.video ? ('webinar' in _ctx.video ? ['fad', 'video'] : ['fad','circle-play']) : undefined
    }, {
      default: _withCtx(() => [
        _createVNode(_component_bp_loading, {
          "model-value": !_ctx.video,
          inline: ""
        }, null, 8, ["model-value"]),
        (!!_ctx.video && 'webinar' in _ctx.video && _ctx.video.webinar.active)
          ? (_openBlock(), _createBlock(_component_translate, { key: 0 }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("Play webinar recording")
              ])),
              _: 1
            }))
          : (!!_ctx.video && 'webinar' in _ctx.video && !_ctx.video.webinar.active)
            ? (_openBlock(), _createBlock(_component_translate, { key: 1 }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode("Webinar")
                ])),
                _: 1
              }))
            : (!!_ctx.video)
              ? (_openBlock(), _createBlock(_component_translate, { key: 2 }, {
                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createTextVNode("Play video")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["icon"]),
    (_ctx.video)
      ? (_openBlock(), _createBlock(_component_bp_card, {
          key: 0,
          "tabs-position": _ctx.isWebinar ? 'inside-reverse' : undefined,
          tabs: _ctx.isWebinar ? [{_id: 'active'}] : undefined,
          class: "bp-video-play-view__content",
          "fill-height": "",
          "fill-width": "",
          ref: "cardEl",
          "seamless-header": "",
          "seamless-content": "",
          "seamless-tabs": ""
        }, _createSlots({
          tabs: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: message._id,
                    class: _normalizeClass(['bp-video-play-view__chat-message-wrapper', {
                'bp-video-play-view__chat-message-wrapper--user': message.user === _ctx.user._id && message.user !== 'system',
                'bp-video-play-view__chat-message-wrapper--next': index > 0 && message.user === _ctx.messages[index - 1].user && message.user !== 'system',
                'bp-video-play-view__chat-message-wrapper--system': message.user === 'system'
              }]),
                    "data-message-id": message._id,
                    style: _normalizeStyle(_ctx.messageStyle(message))
                  }, [
                    (message.user === 'system')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.localizeDatetime(message.date, true)), 1),
                          _createElementVNode("span", {
                            innerHTML: _ctx.getSystemMessage(message)
                          }, null, 8, _hoisted_6)
                        ]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (!(index > 0 && message.user === _ctx.messages[index - 1].user))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.getUser(message)), 1),
                                (((_ctx.user.roles.includes('admin') && _ctx.user._id !== 'admin') && _ctx.user._id !== message.user && 'webinar' in _ctx.video && _ctx.video.webinar.active))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                      _withDirectives(_createVNode(_component_bp_icon, {
                                        class: "bp-video-play-view__chat-message-action bp-video-play-view__chat-message-action--user p-sm",
                                        onClick: ($event: any) => {_ctx.deleteMessagesUser = message.user; _ctx.deleteMessagesWindow = true},
                                        icon: true ? 'comment-slash' : 'comment'
                                      }, null, 8, ["onClick"]), [
                                        [_directive_tooltip, { text: _ctx.$gettext('Mute user'), offset: [-4, 0] }]
                                      ]),
                                      _withDirectives(_createVNode(_component_bp_icon, {
                                        class: "bp-video-play-view__chat-message-action bp-video-play-view__chat-message-action--user p-sm",
                                        onClick: ($event: any) => {_ctx.kickWindow = true; _ctx.userToKick = message.user;},
                                        icon: "user-xmark"
                                      }, null, 8, ["onClick"]), [
                                        [_directive_tooltip, { text: _ctx.$gettext('Kick user'), offset: [-4, 0] }]
                                      ])
                                    ]))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_9, [
                            (message.meta?.reply)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList([_ctx.getRepliedMessage(message)], (repliedMessage) => {
                                  return (_openBlock(), _createElementBlock("blockquote", {
                                    key: repliedMessage._id,
                                    style: _normalizeStyle(_ctx.messageStyle(repliedMessage)),
                                    onClick: ($event: any) => (_ctx.scrollMessageIntoView(repliedMessage)),
                                    class: "bp-video-play-view__chat-message-quote cursor-pointer"
                                  }, [
                                    (_ctx.getUser(repliedMessage))
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.getUser(repliedMessage)), 1))
                                      : _createCommentVNode("", true),
                                    (_openBlock(), _createBlock(_resolveDynamicComponent(repliedMessage.user === 'system' ? 'em' : 'span'), {
                                      class: "bp-video-play-view__chat-message-quote-content",
                                      innerHTML: _ctx.getSystemMessage(repliedMessage) || repliedMessage.content
                                    }, null, 8, ["innerHTML"]))
                                  ], 12, _hoisted_10))
                                }), 128))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_12, [
                              (('webinar' in _ctx.video && _ctx.video.webinar.active))
                                ? _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
                                    key: 0,
                                    class: "p-sm",
                                    onClick: ($event: any) => (_ctx.replyMessage = message._id),
                                    icon: "reply"
                                  }, null, 8, ["onClick"])), [
                                    [_directive_tooltip, { text: _ctx.$gettext('Reply to message'), offset: [-4, 0] }]
                                  ])
                                : _createCommentVNode("", true),
                              ((((_ctx.user.roles.includes('admin') && _ctx.user._id !== 'admin') || message.user === _ctx.user._id) && 'webinar' in _ctx.video && _ctx.video.webinar.active))
                                ? _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
                                    key: 1,
                                    class: "p-sm",
                                    onClick: ($event: any) => (_ctx.deleteMessage(message)),
                                    icon: "xmark"
                                  }, null, 8, ["onClick"])), [
                                    [_directive_tooltip, { text: _ctx.$gettext('Delete message'), offset: [-4, 0] }]
                                  ])
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("span", {
                              innerHTML: _ctx.getContent(message)
                            }, null, 8, _hoisted_13)
                          ]),
                          _createElementVNode("em", _hoisted_14, [
                            _createElementVNode("a", {
                              onClick: ($event: any) => (_ctx.player?.seekTo(message.timestamp, true))
                            }, _toDisplayString(_ctx.formatTimestamp(message)), 9, _hoisted_15),
                            _createElementVNode("span", null, _toDisplayString(_ctx.localizeDatetime(message.date, true)), 1)
                          ])
                        ], 64))
                  ], 14, _hoisted_4))
                }), 128))
              ], 512),
              ('webinar' in _ctx.video && _ctx.video.webinar.active)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createVNode(_component_bp_input, {
                      disabled: _ctx.messageDisabled,
                      maxlength: _ctx.chatMaxlength,
                      resizable: false,
                      rows: 1,
                      onKeydown: _withModifiers(_ctx.submitMessage, ["stop"]),
                      class: "bp-video-play-view__message-input",
                      ref: "messageEl",
                      type: "textarea",
                      modelValue: _ctx.message,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.message) = $event))
                    }, _createSlots({ _: 2 }, [
                      (_ctx.replyMessage)
                        ? {
                            name: "before",
                            fn: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.getRepliedMessage()], (repliedMessage) => {
                                return (_openBlock(), _createElementBlock("blockquote", {
                                  key: repliedMessage._id,
                                  class: "bp-video-play-view__chat-message-quote",
                                  style: _normalizeStyle({
                  ..._ctx.messageStyle(repliedMessage),
                  margin: 'var(--padding) var(--padding) 0 var(--padding)',
                  width: 'auto',
                })
                                }, [
                                  _createElementVNode("div", _hoisted_17, [
                                    (_ctx.getUser(repliedMessage))
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.getUser(repliedMessage)), 1))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_bp_icon, {
                                      class: "bp-video-play-view__chat-message-quote-discard",
                                      icon: "xmark",
                                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.replyMessage = undefined))
                                    })
                                  ]),
                                  (_openBlock(), _createBlock(_resolveDynamicComponent(repliedMessage.user === 'system' ? 'em' : 'span'), {
                                    class: "bp-video-play-view__chat-message-quote-content",
                                    innerHTML: _ctx.getSystemMessage(repliedMessage) || repliedMessage.content
                                  }, null, 8, ["innerHTML"]))
                                ], 4))
                              }), 128))
                            ]),
                            key: "0"
                          }
                        : undefined
                    ]), 1032, ["disabled", "maxlength", "onKeydown", "modelValue"]),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("span", _hoisted_20, [
                        _createVNode(_component_translate, {
                          class: "flex-1",
                          "translate-params": { user: _ctx.currentUsername }
                        }, {
                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                            _createTextVNode("Hello "),
                            _createElementVNode("strong", null, "%{user}", -1)
                          ])),
                          _: 1
                        }, 8, ["translate-params"]),
                        _createVNode(_component_bp_loading, {
                          "model-value": !_ctx.currentUsername,
                          inline: ""
                        }, null, 8, ["model-value"])
                      ]),
                      _createVNode(_component_bp_popover, {
                        "model-value": _ctx.chatSettingsVisible,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.chatSettingsVisible = !_ctx.chatSettingsVisible)),
                        data: _ctx.chatSettingsData,
                        "search-property": "name"
                      }, {
                        "option-system-message": _withCtx(({ option }) => [
                          _createElementVNode("span", _hoisted_21, [
                            _createElementVNode("span", null, _toDisplayString(option.name), 1),
                            _createVNode(_component_bp_input, {
                              type: "toggle",
                              "model-value": _ctx.showSystemMessages,
                              seamless: "",
                              style: {"pointer-events":"none"}
                            }, null, 8, ["model-value"])
                          ])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_bp_button, {
                            class: "flex-0",
                            icon: ['far', 'gear'],
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.chatSettingsVisible = !_ctx.chatSettingsVisible))
                          })
                        ]),
                        _: 1
                      }, 8, ["model-value", "data"]),
                      _createVNode(_component_bp_button, {
                        disabled: _ctx.messageDisabled,
                        class: "flex-0 ws-nw",
                        icon: ['far', 'paper-plane-top'],
                        onClick: _ctx.submitMessage
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_translate, null, {
                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                              _createTextVNode("Send")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ], 512), [
              [_vShow, _ctx.activeChatTab === 'chat']
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_22, [
              _createVNode(_component_bp_table, {
                unwrap: "",
                data: _ctx.users,
                headers: _ctx.userListHeaders,
                selectable: false,
                searchable: false,
                sortable: false,
                "disabled-rows": (item) => item._id === _ctx.user._id
              }, _createSlots({ _: 2 }, [
                (('webinar' in _ctx.video && _ctx.video.webinar.active))
                  ? {
                      name: "actions",
                      fn: _withCtx(({ item }) => [
                        (new Date(item.muted_datetime).getTime() > new Date().getTime())
                          ? _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
                              key: 0,
                              icon: "comment",
                              onClick: ($event: any) => {_ctx.unmuteUser = item._id; _ctx.unmuteUserWindow = true;},
                              disabled: !_ctx.currentUserIds.has(item._id)
                            }, null, 8, ["onClick", "disabled"])), [
                              [_directive_tooltip, { text: _ctx.$gettext('Unmute user'), offset: [-4, 0] }]
                            ])
                          : _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
                              key: 1,
                              icon: "comment-slash",
                              onClick: ($event: any) => {_ctx.deleteMessagesUser = item._id;
                _ctx.deleteMessagesWindow = true; _ctx.muteUser = true;},
                              disabled: !_ctx.currentUserIds.has(item._id)
                            }, null, 8, ["onClick", "disabled"])), [
                              [_directive_tooltip, { text: _ctx.$gettext('Mute user'), offset: [-4, 0] }]
                            ]),
                        _withDirectives(_createVNode(_component_bp_icon, {
                          icon: "message-xmark",
                          onClick: ($event: any) => {_ctx.deleteMessagesUser = item._id; _ctx.deleteMessagesWindow = true; _ctx.muteUser = false;},
                          disabled: !_ctx.currentUserIds.has(item._id) && _ctx.webinarChat?.messages.filter(message => message.user === item._id).length === 0
                        }, null, 8, ["onClick", "disabled"]), [
                          [_directive_tooltip, { text: _ctx.$gettext('Delete messages'), offset: [-4, 0] }]
                        ]),
                        _withDirectives(_createVNode(_component_bp_icon, {
                          icon: "user-xmark",
                          onClick: ($event: any) => {_ctx.kickWindow = true; _ctx.userToKick = item._id},
                          disabled: !_ctx.currentUserIds.has(item._id)
                        }, null, 8, ["onClick", "disabled"]), [
                          [_directive_tooltip, { text: _ctx.$gettext('Kick user'), offset: [-4, 0] }]
                        ])
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["data", "headers", "disabled-rows"])
            ], 512), [
              [_vShow, (_ctx.activeChatTab === 'users' && 'webinar' in _ctx.video)]
            ])
          ]),
          [_ctx.headerSlot]: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(['bp-video-play-view__toolbar', {
          'bp-video-play-view__toolbar--admin': (_ctx.user.roles.includes('admin') && !_ctx.user.roles.includes('impersonate_user') && _ctx.user._id !== 'admin' && 'webinar' in _ctx.video)
        }])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(['flex', 'gap-md', 'p-md', 'pl-sm', {
            'pr-sm': (!_ctx.user.roles.includes('admin') || _ctx.user.roles.includes('impersonate_user') || _ctx.user._id === 'admin' || !('webinar' in _ctx.video))
          }]),
                style: {"align-items":"center"}
              }, [
                (!_ctx.user.roles.includes('anonymous'))
                  ? (_openBlock(), _createBlock(_component_bp_button, {
                      key: 0,
                      to: { name: 'video.area' },
                      icon: "arrow-left",
                      style: {"justify-self":"flex-start"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_translate, null, {
                          default: _withCtx(() => _cache[20] || (_cache[20] = [
                            _createTextVNode("Back")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.getTranslated(_ctx.video.name))
                  ? (_openBlock(), _createElementBlock("h2", _hoisted_23, _toDisplayString(_ctx.getTranslated(_ctx.video.name)), 1))
                  : _createCommentVNode("", true)
              ], 2),
              ((_ctx.user.roles.includes('admin') && !_ctx.user.roles.includes('impersonate_user') && _ctx.user._id !== 'admin' && 'webinar' in _ctx.video))
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    _createVNode(_component_bp_toggle_button, {
                      data: [
              { _id: 'chat', icon: 'message', text: _ctx.$gettext('Chat'), click: () => _ctx.activeChatTab = 'chat' },
              { _id: 'users', icon: 'users', text: _ctx.$gettext('Users'), click: () => _ctx.activeChatTab = 'users' },
            ]
                    }, null, 8, ["data"])
                  ]))
                : _createCommentVNode("", true)
            ], 2)
          ]),
          [_ctx.contentSlot]: _withCtx(() => [
            _createElementVNode("div", _hoisted_25, [
              _createVNode(_component_bp_youtube_iframe, {
                src: 'https://www.youtube-nocookie.com/embed/' + _ctx.video.youtube_id,
                title: _ctx.getTranslated(_ctx.video.name),
                vars: { autoplay: 1, color: 'white', hl: _ctx.currentLanguageISOString(), modestbranding: 1, rel: 0, showinfo: 0 },
                style: _normalizeStyle(_ctx.videoStyle),
                onReady: _ctx.ready,
                class: "bp-video-play-view__video",
                height: "auto",
                ref: "player",
                width: "100%"
              }, null, 8, ["src", "title", "vars", "style", "onReady"]),
              (_ctx.getTranslated(_ctx.video.description))
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "bp-video-play-view__description",
                    innerHTML: _ctx.getTranslated(_ctx.video.description)
                  }, null, 8, _hoisted_26))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 2
        }, [
          (_ctx.video.tags && _ctx.video.tags.length > 0)
            ? {
                name: _ctx.footerSlot,
                fn: _withCtx(() => [
                  _createElementVNode("div", _hoisted_27, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.video.tags, (tag) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: tag,
                        class: "bp-video-play-view__video-tag",
                        title: _ctx.$gettext('Search for videos with the tag #%{tag}', { tag: tag.toLowerCase() }),
                        onClick: ($event: any) => (_ctx.router.push({
              name: 'video.area',
              query: {
                search: `#${tag.toLowerCase()}`,
              }
            }))
                      }, [
                        _createVNode(_component_bp_icon, {
                          class: "pr-xs",
                          icon: "hashtag"
                        }),
                        _createElementVNode("span", null, _toDisplayString(tag.toLowerCase()), 1)
                      ], 8, _hoisted_28))
                    }), 128))
                  ])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["tabs-position", "tabs"]))
      : _createCommentVNode("", true),
    _createVNode(_component_bp_window, {
      modelValue: _ctx.usernameWindow,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.usernameWindow) = $event))
    }, {
      footer: _withCtx(() => [
        (!_ctx.webinarJoined)
          ? (_openBlock(), _createBlock(_component_bp_button, {
              key: 0,
              disabled: !_ctx.usernameValid,
              onClick: _ctx.joinChat
            }, {
              default: _withCtx(() => [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                    _createTextVNode("Participate")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]))
          : (_openBlock(), _createBlock(_component_bp_button, {
              key: 1,
              disabled: !_ctx.usernameValid,
              onClick: _ctx.changeUsername
            }, {
              default: _withCtx(() => [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                    _createTextVNode("Change username")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]))
      ]),
      default: _withCtx(() => [
        _createVNode(_component_bp_input, {
          label: _ctx.$gettext('Username'),
          pattern: "[\\wÄÖÜäöüß]",
          "onUpdate:modelValue": [
            _ctx.checkUsername,
            _cache[4] || (_cache[4] = ($event: any) => ((_ctx.username) = $event))
          ],
          "label-position": "top",
          modelValue: _ctx.username,
          onKeydown: _withModifiers(_ctx.joinChat, ["stop"]),
          error: !_ctx.usernameValid ? _ctx.$gettext('Username invalid') : undefined
        }, null, 8, ["label", "onUpdate:modelValue", "modelValue", "onKeydown", "error"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_bp_window, {
      modelValue: _ctx.unmuteUserWindow,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.unmuteUserWindow) = $event))
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_bp_button, {
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.confirmUnmuteUser()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[24] || (_cache[24] = [
                _createTextVNode("Unmute user")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_bp_form_button, {
          type: "cancel",
          action: () => _ctx.unmuteUserWindow = false
        }, null, 8, ["action"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_translate, {
          "translate-params": { unmuteUser: _ctx.getUserById(_ctx.unmuteUser) }
        }, {
          default: _withCtx(() => _cache[23] || (_cache[23] = [
            _createTextVNode("Do you really want to unmute the following user: %{unmuteUser}")
          ])),
          _: 1
        }, 8, ["translate-params"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_bp_window, {
      modelValue: _ctx.kickWindow,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.kickWindow) = $event))
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_bp_button, {
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.kickUser()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[26] || (_cache[26] = [
                _createTextVNode("Kick user")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_bp_form_button, {
          type: "cancel",
          action: () => _ctx.kickWindow = false
        }, null, 8, ["action"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_translate, {
          "translate-params": { userToKick: _ctx.getUserById(_ctx.userToKick) }
        }, {
          default: _withCtx(() => _cache[25] || (_cache[25] = [
            _createTextVNode("Do you really want to kick the following user: %{userToKick}")
          ])),
          _: 1
        }, 8, ["translate-params"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_bp_window, {
      modelValue: _ctx.deleteMessagesWindow,
      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.deleteMessagesWindow) = $event))
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_bp_button, {
          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.deleteMessages(_ctx.messages.filter(message => Array.from(_ctx.deleteMessagesSelection).includes(message._id)) || [])))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[28] || (_cache[28] = [
                _createTextVNode("Confirm")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_bp_form_button, {
          type: "cancel",
          action: () => _ctx.deleteMessagesWindow = false
        }, null, 8, ["action"])
      ]),
      default: _withCtx(() => [
        (_ctx.muteUser)
          ? (_openBlock(), _createBlock(_component_bp_input, {
              key: 0,
              type: "number",
              modelValue: _ctx.mutedTime,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.mutedTime) = $event)),
              min: 1
            }, {
              default: _withCtx(() => [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[27] || (_cache[27] = [
                    _createTextVNode("Muted time in minutes")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.deleteMessagesUser)
          ? (_openBlock(), _createBlock(_component_bp_table, {
              key: 1,
              data: _ctx.messages.filter(message => message.user === _ctx.deleteMessagesUser || '') || [],
              headers: _ctx.deleteMessagesHeaders,
              searchable: false,
              "onUpdate:selection": _cache[11] || (_cache[11] = ($event: any) => (_ctx.deleteMessagesSelection = $event)),
              "default-sorting": "date",
              unwrap: ""
            }, null, 8, ["data", "headers"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_bp_window, {
      modelValue: _ctx.webinarEndWindow,
      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.webinarEndWindow) = $event))
    }, {
      footer: _withCtx(() => [
        (!_ctx.user.roles.includes('anonymous'))
          ? (_openBlock(), _createBlock(_component_bp_button, {
              key: 0,
              to: { name: 'video.area' }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[31] || (_cache[31] = [
                    _createTextVNode("Back to video area")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_bp_button, { onClick: _ctx.logout }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[32] || (_cache[32] = [
                _createTextVNode("Logout")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[29] || (_cache[29] = [
            _createTextVNode("The webinar has ended.")
          ])),
          _: 1
        }),
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[30] || (_cache[30] = [
            _createTextVNode("Thank you for your participation.")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}