
import { $gettext } from 'vue-gettext';
import { availableColors, cssColor, Modifier } from '@/utils/color';
import { BaseDoc } from '@/components/virtual-scroller/BpVirtualScroller';
import { Chat, Message, Participant, Recording, useVideoStore, Webinar } from '@/stores/video';
import { computed, CSSProperties, defineComponent, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import { currentLanguageISOString, getTranslated } from '@/translation';
import { hashString } from '@/utils/string';
import { Header, toDatetime } from '@/components/table/BpTable';
import { json } from '@sahnee/ajax';
import { localizeDatetime } from '@/utils/date';
import { logout } from '@/utils/auth';
import { partition } from '@/utils/array';
import { setStyles } from '@/utils/css';
import { slashIcon } from '@/components/icon/BpIcon';
import { useConfigStore } from '@/stores/config';
import { User, useUserStore } from '@/stores/user';
import { useRouter } from 'vue-router';
import { useUser } from '@/utils/user';
import BpCardVue from '@/components/card/BpCard.vue';
import BpInputVue from '@/components/BpInput.vue';
import BpToast from '@/components/toast/BpToasts';
import BpWebSocket from '@/utils/web-socket';
import useCompactMode from '@/compositions/use-compact-mode';

export default defineComponent({
  name: 'bp-video-play-view',
  setup() {
    ///-------------------------------------------------------------------
    /// WEBSOCKET
    ///-------------------------------------------------------------------

    const ws = new BpWebSocket({ handler: 'webinar-chat' });

    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();
    const origin = computed(() => window.location.origin)

    ///-------------------------------------------------------------------
    /// CHAT MESSAGE LENGTH LIMIT
    ///-------------------------------------------------------------------
    
    const configStore = useConfigStore();
    const chatMaxlength = ref(100);

    watch(() => configStore.isLoading(), () => {
      if (!configStore.loaded || configStore.isLoading()) { return }
      chatMaxlength.value = parseInt(configStore.config.webinar.max_message_length as string);
    }, { immediate: true });

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------
    
    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// USER
    ///-------------------------------------------------------------------

    const user = useUser();
    const userStore = useUserStore();

    ///-------------------------------------------------------------------
    /// VIDEO MAX SIZE
    ///-------------------------------------------------------------------
    
    const cardEl = ref<typeof BpCardVue>();
    const videoStyle = ref<CSSProperties>({});

    function setVideoStyle() {
      window.setTimeout(() => {
        if (!cardEl.value) { return }
        const contentEl = cardEl.value.$el.querySelector('.bp-card__content') as HTMLDivElement;
        if (!contentEl) { return }
        videoStyle.value = { '--video-max-height': contentEl.getBoundingClientRect().height + 'px' };
      }, 0)
    }

    watch(() => [compactMode?.value, cardEl.value], setVideoStyle, { immediate: true });
    onMounted(() => {
      setVideoStyle();
      addEventListener('resize', setVideoStyle, { passive: true });
    })
    onUnmounted(() => {
      removeEventListener('resize', setVideoStyle);
    })


    ///-------------------------------------------------------------------
    /// WEBINAR CHAT
    ///-------------------------------------------------------------------

    const activeChatTab = ref('chat');

    const isWebinar = computed(() => !!(video.value && 'webinar' in video.value));
    const chatEl = ref<HTMLDivElement>();
    const webinarChat = ref<Chat>();
    const messages = ref<Message[]>([]);

    const chatSettingsVisible = ref(false);

    const chatSettingsData = ref([
      {
        _id: 'edit-username',
        name: $gettext('Change username'),
        click: () => {
          usernameWindow.value = true;
          username.value = '';
          chatSettingsVisible.value = false;
        }
      },
      {
        _id: 'system-message',
        name: $gettext('Show system messages'),
        click: () => {
          showSystemMessages.value = !showSystemMessages.value;
        }
      },
    ])
    const showSystemMessages = ref(true);

    const webinarJoined = ref(false);

    const usernameWindow = ref(false);
    const currentUsername = ref('');
    const username = ref('');
    const messageEl = ref<typeof BpInputVue>();
    const message = ref<string>();
    const messageDisabledTimeout = ref(NaN);
    const messageDisabled = ref(false);
    const mutedTime = ref(3);
    const userToKick = ref('');
    const kickWindow = ref(false);

    const sendingMessage = ref(false);

    const replyMessage = ref<string>();

    const muteUser = ref(false);

    const unmuteUser = ref('');
    const unmuteUserWindow = ref(false);

    const webinarEndWindow = ref(false);

    const userFromStore = ref<User>();
    watch(() => user.value._id, async () => {
      if (!user.value._id) {
        return;
      }
      const response = await userStore.readById(user.value._id);
      if (response?.success && response.data) {
        userFromStore.value = response.data
      }
    }, { immediate: true });

    const users = ref<BaseDoc[]>([]);
    const userListHeaders: Header[] = [
      {
        _id: 'username',
        name: $gettext('Username'),
        // transform: (user: Participant) => getUserById(user._id)
      }
    ];

    const currentUserIds = ref(new Set<string>());

    async function updateUsers() {
      if (!user.value.roles.includes('admin') || !video.value ||!webinarChat.value) {
        return;
      }
      if ('webinar' in video.value && video.value.webinar.active) {
        const result = await ws.call<unknown, { userList: string[] }>('user-list', {});
        currentUserIds.value = new Set(result.userList);
        const [onlineUserList, offlineUserList] = partition(webinarChat.value.users, user => result.userList.includes(user._id));

        users.value = [
          ...onlineUserList.length > 0 ? [{_id: '_subheading_online_users', _subheading: true, name: $gettext('Online users'), _children: []}] : [],
          ...onlineUserList.sort((a, b) => a.username < b.username ? -1 : 1),
          ...offlineUserList.length > 0 ? [{_id: '_subheading_offline_users', _subheading: true, name: $gettext('Offline users'), _children: []}] : [],
          ...offlineUserList.sort((a, b) => a.username < b.username ? -1 : 1)
        ];
      } else if ('webinar' in video.value) {
        users.value = [
          {_id: '_subheading_users', _subheading: true, name: $gettext('Participants'), _children: []},
          ...webinarChat.value.users.sort((a, b) => a.username < b.username ? -1 : 1)
        ]
      }
    }

    const abortController = ref(new AbortController())
    const usernameValid = ref(true)
    async function checkUsername(username: string) {
      try {
        abortController.value.abort();
        abortController.value = new AbortController();
        const response = await json<{
          status: boolean
        }>('/api/webinar-check-username', {
          method: 'POST',
          json: {
            token: (video.value as Webinar).webinar.token,
            username,
          },
          signal: abortController.value.signal
        });
        usernameValid.value = response.status;
      } catch (error: unknown) {
        return;
      }
    }

    function updateMessageDisabled() {
      const mutedDateTime = webinarChat.value?.users.find((participant) => participant._id === user.value._id)?.muted_datetime;
      if (!mutedDateTime) {
        messageDisabled.value = false;
        return;
      }
      const mutedMilliseconds = new Date(mutedDateTime).getTime() - new Date().getTime();
      if (mutedMilliseconds > 0) {
        messageDisabled.value = true;
        if (!isNaN(messageDisabledTimeout.value)) {
          window.clearTimeout(messageDisabledTimeout.value);
        }
        messageDisabledTimeout.value = window.setTimeout(() => messageDisabled.value = false, mutedMilliseconds);
      } else {
        messageDisabled.value = false;
      }
    }

    watch(() => [message.value, messageEl.value], () => {
      nextTick(() => {
        if (!messageEl.value) {
          return;
        }
        const textarea = messageEl.value.$el.querySelector('textarea') as HTMLTextAreaElement;
        textarea.style.height = '0px';
        textarea.style.height = (textarea.scrollHeight + 0.4) + 'px';
      })
    }, { immediate: true })

    const deleteMessagesWindow = ref(false);
    const deleteMessagesUser = ref();
    const deleteMessagesSelection = ref(new Set<string>())

    const deleteMessagesHeaders: Header[] = [
      {
        _id: 'content',
        name: $gettext('Message'),
      },
      {
        _id: 'date',
        name: $gettext('Date & time'),
        transform: toDatetime,
      },
    ]

    function messageStyle(message: Message) {
      const user = getUser(message);
      const color = user ? availableColors[Math.abs(hashString(user)) % (availableColors.length - 5)] : 'var(--theme-text-secondary)';
      return {
        '--video-play-chat-message-color': cssColor(color),
        '--video-play-chat-message-text': cssColor(color, Modifier.TEXT),
      }
    }

    function getUser(message: Message) {
      return webinarChat.value?.users.find((user) => user._id === message.user)?.username || '';
    }

    function getUserById(userid: string) {
      return webinarChat.value?.users.find((user) => user._id === userid)?.username || ''
    }

    function getRepliedMessage(message?: Message): Message {
      return webinarChat.value?.messages.find(msg => msg._id === (message?.meta?.reply || replyMessage.value))
        || { _id: '', content: '', date: '', timestamp: 0, type: 'text', user: '' } as Message;
    }

    function getContent(message: Message) {
      const patternReplacements = new Set<{ pattern: RegExp, replacement: string }>()
      for (const match of message.content.matchAll(/data-mention="(.+?)"/g)) {
        const id = match[1];
        const username = webinarChat.value?.users.find(user => user._id === id)?.username || '';
        patternReplacements.add({
          pattern: new RegExp(`<span data-mention="${id}">.*?</span>`, 'g'),
          replacement: `<span data-mention="${id}">@${username}</span>`
        })
      }
      let msg = message.content;
      for (const { pattern, replacement } of patternReplacements.values()) {
        msg = msg.replace(pattern, replacement);
      }
      return msg;
    }

    function kickUser() {
      if (!userToKick.value) {
        return;
      }
      ws.call('kick-user', {
        user: userToKick.value
      })

      kickWindow.value = false;
    }

    function formatTimestamp(message: Message) {
      const hours = Math.trunc(message.timestamp / 3600) || undefined;
      const minutes = Math.trunc((message.timestamp % 3600) / 60);
      const seconds = Math.trunc(message.timestamp % 60);
      return [hours, minutes, seconds]
        .filter(number => number !== undefined)
        .map(number => number?.toString().padStart(2, '0'))
        .join(':')
    }

    async function joinChat(event: Event) {
      if ((event instanceof KeyboardEvent && event.code !== 'Enter') || (!user.value._id) || !video.value || ('webinar' in video.value && !video.value.webinar.active)) {
        return;
      }
      ws.call('join', {
        _id: user.value._id,
        name: username.value,
      })
      webinarJoined.value = true;
      usernameWindow.value = false;
      currentUsername.value = username.value;
      store.reload();
      localStorage.setItem('bp:webinar_' + video.value?._id + '_user_' + user.value._id, JSON.stringify({ _id: user.value._id, username: username.value }));
    }

    async function submitMessage(event: Event) {
      if ((event instanceof KeyboardEvent && (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey)) || !message.value || message.value.trim() === '' || !isWebinar.value || sendingMessage.value/* || !(video.value as Webinar).webinar.active*/) {
        return;
      }
      if (!(event instanceof KeyboardEvent) || event.code === 'Enter') {
        sendingMessage.value = true;
        event.preventDefault();
        await ws.call('new-message', {
          content: message.value.trim(),
          timestamp: player.value?.getCurrentTime(),
          meta: replyMessage.value ? { reply: replyMessage.value } : undefined,
        })
        message.value = '';
        replyMessage.value = undefined;
        sendingMessage.value = false;
      }
    }

    function deleteMessage(message: Message) {
      if (!message || !isWebinar.value) {
        return;
      }
      ws.call('delete-messages', {
        delete_messages: [message],
      });
    }

    function confirmUnmuteUser() {
      if (!unmuteUser.value || !isWebinar.value) {
        return;
      }
      ws.call('unmute-user', {
        user: unmuteUser.value,
      })
      unmuteUserWindow.value = false;
    }

    function deleteMessages(messages: Message[]) {
      if (!messages || !isWebinar.value) {
        return;
      }
      if (muteUser.value) {
        ws.call('mute-user', {
          user: deleteMessagesUser.value,
          minutes: mutedTime.value,
          delete_messages: messages,
        });
      } else {
        ws.call('delete-messages', {
          delete_messages: messages,
        });
      }
      deleteMessagesWindow.value = false;
    }

    function getSystemMessage(message: Message) {
      switch (message.content) {
        case 'message_deleted':
          return $gettext('This message was deleted.');
        case 'user_muted':
          return $gettext('The user <strong>%{user}</strong> was muted for %{minutes} minutes.', {user: getUserById(message.meta?.user as string), minutes: message.meta?.minutes});
        case 'user_unmuted':
          return $gettext('The user <strong>%{user}</strong> was unmuted.', {user: getUserById(message.meta?.user as string)});
        case 'user_kicked':
          return $gettext('The user <strong>%{user}</strong> was kicked from the webinar.', {user: getUserById(message.meta?.user as string)});
        case 'webinar_ended':
          return $gettext('The webinar has ended.') + '<br>' + $gettext('Thank you for your participation.');
        default:
          return '';
      }
    }

    function scrollToBottom() {
      nextTick(() => chatEl.value?.scrollTo({
        left: 0,
        top: chatEl.value?.scrollHeight || 0,
        behavior: 'smooth'
      }));
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    watch(() => [webinarChat.value, chatEl.value], ([_chat, _chatEl], [oldChat, oldChatEl]) => {
      if (oldChat && !(oldChat instanceof HTMLDivElement) && oldChat.messages.length > 0 && oldChatEl !== undefined) {
        return;
      }
      window.setTimeout(scrollToBottom, 0);
    });

    function scrollMessageIntoView(message: Message) {
      const messageEl = chatEl.value?.querySelector(`[data-message-id="${message._id}"]`) as HTMLElement;
      if (!messageEl) {
        return;
      }
      chatEl.value?.scrollTo({
        left: 0,
        top: messageEl.offsetTop + 1,
        behavior: 'smooth'
      })
    }

    onMounted(() => {
      ws.on('message:new-message', (message: { data: Message }) => {
        store.addMessageToChat(video.value?._id || '', message.data);
        scrollToBottom();
      });
      ws.on('message:delete-messages', (message: { data: Message[] }) => {
        store.updateMessagesInChat(video.value?._id || '', message.data);
      });
      ws.on('message:mute-user', (message: { data: { messages: Message[], users: Participant[] } }) => {
        store.updateMessagesInChat(video.value?._id || '', message.data.messages);
        store.updateParticipantsInChat(video.value?._id || '', message.data.users);
        updateUsers();
        updateMessageDisabled();
        scrollToBottom();
      });
      ws.on('message:unmute-user', (message: { data: { messages: Message[], users: Participant[] } }) => {
        store.updateMessagesInChat(video.value?._id || '', message.data.messages);
        store.updateParticipantsInChat(video.value?._id || '', message.data.users);
        updateUsers();
        updateMessageDisabled();
        scrollToBottom();
      });
      ws.on('message:edit', (message: { data: { messages: Message[], users: Participant[] } }) => {
        store.updateParticipantsInChat(video.value?._id || '', message.data.users);
        updateUsers();
      });
      ws.on('message:join', (message: { data: Participant[] }) => {
        store.updateParticipantsInChat(video.value?._id || '', message.data);
        updateUsers();
        currentUsername.value = webinarChat.value?.users.find(u => u._id === user.value._id)?.username || '';
      });
      ws.on('message:leave', () => {
        updateUsers();
      });
      ws.on('message:kick-user', (message: { data: { messages: Message[], users: Participant[] } }) => {
        store.updateMessagesInChat(video.value?._id || '', message.data.messages);
        store.updateParticipantsInChat(video.value?._id || '', message.data.users);
        updateUsers();
        updateMessageDisabled();
        scrollToBottom();
      });
      ws.on('message:kicked-user', (message: { data: string }) => {
        if (user.value._id === message.data) {
          // Anonymous user
          if (user.value._id.startsWith('user_')) {
            logout(user.value);
          }
          // Logged in customer with active support
          else {
            router.replace({ name: 'home' });
          }
          BpToast.show({
            color: 'red',
            title: $gettext('Kicked'),
            content: $gettext('You were kicked from the webinar.'),
            icon: 'right-from-bracket',
          })
        }
        // store.updateParticipantsInChat(video.value?._id || '', users.data);
      });
      ws.on('message:end-webinar', (message: { data: { messages: Message[], users: Participant[] } }) => {
        store.updateMessagesInChat(video.value?._id || '', message.data.messages);
        store.updateParticipantsInChat(video.value?._id || '', message.data.users);
        updateUsers();
        messageDisabled.value = true;
        scrollToBottom();
        webinarEndWindow.value = true
      });
    })

    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const store = useVideoStore(true);

    const video = ref<Recording | Webinar>();

    const headerSlot = computed(() => isWebinar.value ? 'tabs-header' : 'header');
    const contentSlot = computed(() => isWebinar.value ? 'tabs-content' : 'default');
    const footerSlot = computed(() => isWebinar.value ? 'tabs-footer' : 'footer');

    function loadVideo() {
      const doc = store.getBySlug(router.currentRoute.value.params.slug as string)
      video.value = doc;
    }

    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      loadVideo()
    }, { immediate: true });

    ws.on('open', async () => await connect());
    watch(() => video.value, async () => await connect())

    function changeUsername() {
      ws.call('edit', {
        _id: user.value._id,
        name: username.value,
      });
      currentUsername.value = username.value;
      usernameWindow.value = false;
      localStorage.setItem('bp:webinar_' + video.value?._id + '_user_' + user.value._id, JSON.stringify({ _id: user.value._id, username: username.value }));
    }

    async function connect() {
      if (!isWebinar.value || !video.value) {
        return;
      }
      webinarChat.value = await store.loadChatByWebinarId(video.value._id);
      updateUsers();
      if ('webinar' in video.value && !video.value.webinar.active) {
        return;
      }
      const userFromLocalStorage = JSON.parse(localStorage.getItem('bp:webinar_' + video.value?._id + '_user_' + user.value._id) || '{}');
      if (typeof userFromLocalStorage === 'object' && 'username' in userFromLocalStorage) {
        username.value = userFromLocalStorage.username;
        ws.call('join', {
          _id: user.value._id,
          name: username.value,
        });
        webinarJoined.value = true;
        currentUsername.value = username.value;
      } else {
        usernameWindow.value = true;
      }
      updateMessageDisabled();
    }

    watch(() => [webinarChat.value, showSystemMessages.value], () => {
      if (!webinarChat.value) {
        return;
      }
      messages.value = showSystemMessages.value ? webinarChat.value.messages : webinarChat.value.messages.filter(message => message.user !== 'system');
    }, { deep: true })

    setStyles({
      ['[data-mention="' + user.value._id + '"]']: {
        background: 'var(--theme-intense-border)',
        padding: '0 var(--padding-xs)',
        color: 'white',
        'border-radius': 'var(--border-radius-sm)',
        'font-weight': 'bold',
      }
    })

    ///-------------------------------------------------------------------
    /// YOUTUBE IFRAME API
    ///-------------------------------------------------------------------

    // eslint-disable-next-line no-undef
    const player = ref<YT.Player>();

    // eslint-disable-next-line no-undef
    function ready(/* event: YT.PlayerEvent */) {
      if (!player.value) {
        return;
      }
      // AUTOPLAY
      // player.value.playVideo();
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      activeChatTab,
      cardEl,
      changeUsername,
      chatEl,
      chatMaxlength,
      chatSettingsData,
      chatSettingsVisible,
      checkUsername,
      confirmUnmuteUser,
      contentSlot,
      currentLanguageISOString,
      currentUserIds,
      currentUsername,
      deleteMessage,
      deleteMessages,
      deleteMessagesHeaders,
      deleteMessagesSelection,
      deleteMessagesUser,
      deleteMessagesWindow,
      footerSlot,
      formatTimestamp,
      getContent,
      getRepliedMessage,
      getSystemMessage,
      getTranslated,
      getUser,
      getUserById,
      headerSlot,
      isWebinar,
      joinChat,
      kickUser,
      kickWindow,
      localizeDatetime,
      logout,
      message,
      messageDisabled,
      messageEl,
      messages,
      messageStyle,
      mutedTime,
      muteUser,
      origin,
      player,
      ready,
      replyMessage,
      router,
      showSystemMessages,
      scrollMessageIntoView,
      sendingMessage,
      slashIcon,
      store,
      submitMessage,
      unmuteUser,
      unmuteUserWindow,
      updateUsers,
      user,
      userListHeaders,
      username,
      usernameValid,
      usernameWindow,
      users,
      userToKick,
      video,
      videoStyle,
      webinarChat,
      webinarEndWindow,
      webinarJoined,
    }
  }
});
