import { onMounted, onUnmounted, ref } from 'vue';
import { url } from '@sahnee/ajax';
import { uuid } from './string';
import Deferred from './deferred';
import EventEmitter from '@sahnee/eventemitter';
import Observable from '@sahnee/eventemitter/dist/observer/Observable';

const TYPE_TICK = 'keep-alive';
const TYPE_REPLY = 'reply';
const MESSAGE_EVENT_PREFIX = 'message';

/**
 * Web socket options
 */
interface Options {
  /**
   * The handler module. Defaults to `ws`.
   */
  handler: string;

  /**
   * Connect the websocket automatically.
   */
  autoConnect: boolean;
}

/**
 * A single web socket message.
 */
export interface BpWebSocketMessage<T = unknown> {
  /**
   * The type of the message.
   */
  type: string;

  /**
   * THe unique reference of the message.
   */
  ref: string;

  /**
   * The payload of the message.
   */
  data: T;

  /**
   * Is the message a system message?
   */
  system: boolean;

  /**
   * The raw websocket event injected.
   */
  websocketEvent?: MessageEvent;
}

const DEFAULT_OPTIONS: Options = {
  handler: 'ws',
  autoConnect: true,
};

/**
 * bestinformed WebSocket protocol compatible websockets.
 */
export default class BpWebSocket extends EventEmitter {
  ///-------------------------------------------------------------------
  /// PROPERTIES & GETTERS
  ///-------------------------------------------------------------------

  private readonly _options: Options;
  private _open: Deferred<number> = new Deferred();
  private readonly _replies = new Observable<BpWebSocketMessage>();

  /**
   * The raw websocket.
   */
  public ws: WebSocket | null = null;

  /**
   * Was the owning component unmouted?
   */
  public unmounted = ref(false);

  /**
   * The URL of the websocket.
   */
  public get websocketUrl() {
    return url({
      protocol: window.location.protocol.startsWith('https') ? 'wss' : 'ws',
      url: ['api', this._options.handler]
    });
  }

  ///-------------------------------------------------------------------
  /// LIFECYCLE & EVENTS
  ///-------------------------------------------------------------------

  constructor(opts: Partial<Options> = {}) {
    super();
    this._options = {...DEFAULT_OPTIONS, ...opts};
    onMounted(() => {
      // console.log('Web socket is now available', { url: this.websocketUrl });
      this.unmounted.value = false;
      if (!this.ws && this._options.autoConnect) {
        this.reconnect();
      }
    }),
    onUnmounted(() => {
      // console.log('Web socket is now unavailable', { url: this.websocketUrl });
      this.unmounted.value = true;
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        // console.log('Closing web socket in unmount callback', { url: this.websocketUrl });
        this.ws.close();
      }
    })
  }

  private onClose(event: CloseEvent) {
    // console.log('Web socket has been closed', event, { url: this.websocketUrl });
    this.ws = null;
    this._open = new Deferred();
    this.emit('close', event);
  }

  private onOpen() {
    if (!this.ws) {
      throw new Error('WebSocket is null in onOpen callback');
    }
    // console.log('Web socket has been opened', { url: this.websocketUrl });
    this._open.resolve(this.ws.readyState);
    if (this.unmounted.value) {
      // console.log('Closing web socket in open callback');
      this.ws?.close();
    } else {
      this.emit('open');
    }
  }

  private onMessage(event: MessageEvent) {
    const data = event.data;
    if (typeof data === 'string') {
      const json: BpWebSocketMessage = JSON.parse(data);
      json.websocketEvent = event;
      if (json.system) {
        this.handleSystemMessage(json);
      } else {
        // console.log(`WebSocket got data:`, json, { url: this.websocketUrl });
        this._replies.invoke(json);
        this.emit(MESSAGE_EVENT_PREFIX + ':' + json.type, json);
      }
      this.emit(MESSAGE_EVENT_PREFIX, json)
    }
  }

  private onError(event: Event) {
    // console.error('Websocket error:', event, { url: this.websocketUrl });
    this.emit('error', event);
  }

  private handleSystemMessage(message: BpWebSocketMessage) {
    if (!this.ws) {
      throw new Error('WebSocket is null in handleSystemMessage callback');
    }
    // console.log('Web socket will handle a system message', message.type, { url: this.websocketUrl });
    switch (message.type) {
      case TYPE_TICK: {
        this.ws.send(message.websocketEvent?.data);
        break;
      }
      case TYPE_REPLY: {
        this._replies.invoke(message);
        break;
      }
    }
  }

  ///-------------------------------------------------------------------
  /// PUBLIC API
  ///-------------------------------------------------------------------

  public close() {
    // console.info('Closing web socket', { url: this.websocketUrl });
    if (this.ws !==  null) {
      this.ws.close();
    }
  }

  public reconnect() {
    // console.info('Connecting to web socket', { url: this.websocketUrl });
    this.ws = new WebSocket(this.websocketUrl);
    this.ws.addEventListener('message', this.onMessage.bind(this));
    this.ws.addEventListener('error', this.onError.bind(this));
    this.ws.addEventListener('open', this.onOpen.bind(this));
    this.ws.addEventListener('close', this.onClose.bind(this));
  }

  public async cast<T>(type: string, data: T): Promise<string> {
    if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
      throw new Error('Cannot cast a ' + type + ' message when not connected to the WebSocket.');
    }
    const ref = uuid();
    const json: BpWebSocketMessage = {
      type,
      ref,
      data,
      system: false,
    };
    const text = JSON.stringify(json);
    await this._open.promise;
    this.ws.send(text);
    return ref;
  }

  public async call<T, TResp = unknown>(type: string, data: T, opts: { timeout?: number } = {}): Promise<TResp> {
    if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
      throw new Error('Cannot call a ' + type + ' message when not connected to the WebSocket.');
    }
    const ref = await this.cast(type, data);
    return new Promise<TResp>((resolve, reject) => {
      const removeListener = () => {
        clearTimeout(timeout);
        this._replies.removeObserver(replyListener);
      }

      const replyListener = (msg: BpWebSocketMessage) => {
        if (msg.ref !== ref) {
          return;
        }
        removeListener();
        resolve(msg.data as TResp);
      }

      const timeout = window.setTimeout(() => {
        removeListener();
        reject(new Error('The server took too long to respond to the WebSocket message.'));
      }, opts.timeout || 5000);

      this._replies.addObserver(replyListener);
    });
  }
}
